@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Wix+Madefor+Display:wght@400..800&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --link-accessible: #2540AD;
    --link-blog: #0C0D0E;
    --btn-primary: #4285f4;
    --btn-danger: #ff3547;
    --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --default-font: 'Wix Madefor Display', sans-serif;
    --default-color: #0C0D0E;
    --accent-color: #f651b1; 
    --gradient-color: #6fcffb;
    --accent-secondary-color: #E73C7E; 
    --gradient-secondary-color: #23A6D5;
}

html, body {
    height: auto;
    margin: 0 !important;
}

html{
    height: 100%;
  }
  body {
    min-height: 100%;
  }

body {
    font-size: 1rem !important;
    font-family: "Wix Madefor Display", var(--default-font) !important;
    font-optical-sizing: auto;
    font-style: normal;
    line-height: 1.7 !important;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    background-color: rgb(248, 248, 248);
}

pre {
    background-color: rgb(238, 238, 238);
    background-color: #272822;
    padding: .25rem 1rem;
    margin: 1rem auto;
    font-family: 'Monaco', sans-serif;
    border-radius: .35rem;
    overflow-y: hidden;
    overflow-x: auto;
}

pre code {
    padding: .5rem;
    background: transparent;
    color: #fff;
}

code {
    background: rgb(238, 238, 238);
    white-space: pre;
    word-spacing: normal;
    word-wrap: normal;
    text-align: left;
    word-break: normal;
    font-family: 'Menlo', 'Monaco', sans-serif;
    padding: .25rem;
    font-size: .9rem;
}

a {
    color: var(--link-blog);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    cursor: pointer;
}

p {
    font-family: var(--default-font), sans-serif !important;
    font-size: 1rem !important;
    color: var(--default-color);
    line-height: 1.2rem;
}

app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

app-footer {
    margin-top: auto;
}

.mat-card-content {
    font-size: 1rem;
}

mat-form-field .mat-form-field-outline {
    background-color: #fff;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #2540AD;
}

.mat-typography {
    font-family: "Wix Madefor Display", var(--default-font);
}

.mat-paginator {
    background-color: transparent;
}

.mat-paginator-icon {
    width: 20px !important;
    padding: 0px 2px;
}

.mat-stroked-button {
    background-color: #fff;
    line-height: 36px;
}

.mat-button-disabled {
    background-color: #f6f3f1;
    border: 1px solid #e1dcda;
}

.g-bubble-container {
    display: flex;
    gap: 0px;
    padding: 0rem .9rem;
  }
  
.g-bubble {
    background-color: transparent;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0C0D0E;
    font-size: 12px;
    cursor: pointer;
    transition: 0.3s;
}
  
.g-bubble:hover {
    color: #b6b6b6;
}
  
.g-bubble__active {
    background-color: #0C0D0E;
    border-radius: 4px;
    color: white !important;
}

.grad-bar {
    width: 100%;
    height: 5px;
    background: linear-gradient(-45deg,  #f651b1,  #6fcffb,  #E73C7E,  #23A6D5);
    background-size: 400% 400%;
        -webkit-animation: gradbar 15s ease infinite;
      -moz-animation: gradbar 15s ease infinite;
      animation: gradbar 15s ease infinite;
}